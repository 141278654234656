<template>
  <div>
    <b-breadcrumb>
    <b-breadcrumb-item href="/">
      <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
    </b-breadcrumb-item>
    <b-breadcrumb-item :href="`/ioa/${instituicao.slug}`">{{instituicao.name}}</b-breadcrumb-item>
    <b-breadcrumb-item :href="`/ioa/${instituicao.slug}/t/${campeonato.temporada_id}`">{{campeonato.temporada}}</b-breadcrumb-item>
    <b-breadcrumb-item active>{{ campeonato.nome}}</b-breadcrumb-item>
  </b-breadcrumb>
    <div class="d-flex align-items-center">
  <b-avatar variant="info" :src="'data:image/png;base64,' + instituicao.logo" class="mr-3" size="6rem"></b-avatar>
  <b-jumbotron  :lead="instituicao.description">
    <p>For more information, visit the <a :href="instituicao.website" target="_blank" rel="noopener noreferrer">website</a>.</p>
  </b-jumbotron>
</div>
    <b-tabs content-class="mt-3">
    <b-tab title="Ultimos jogos"><p>Ultimos jogos</p></b-tab>
    <b-tab title="Proximos jogos"><p>proximos jogos</p></b-tab>
    <b-tab title="Classificação"><p>classificacao</p></b-tab>
    <b-tab title="Equipes" >
      <b-card
      tag="article"
      img-top
      v-for="post in equipes"
      :key="post.id"
      class="m-2"
      :img-src="'data:image/png;base64,'+ post.logo"
    >
      <!-- 
        <b-card  img-src="https://picsum.photos/600/300/?image=25" img-alt="Image" img-top
            tag="article" style="max-width: 10rem;" class="mb-3"> -->
      <b-card-text>
        {{ post.nome }}
      </b-card-text>
    </b-card>
    </b-tab>
    <b-tab title="Destaques" disabled><p>I'm a disabled tab!</p></b-tab>
  </b-tabs>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HomeCampeonato",
  props: ["slug"],

  data() {
    return {
      instituicao: {},
      posicoes: [],
      currentPage: 1,
      totalPages: 0,
      campeonato:{},
      equipes: [],
      fields: ["id", "name", "description", "actions"],
    };
  },

  computed: {
    rows() {
      return this.posicoes.length;
    },
  },
  mounted() {
    const campeonato_id = this.$route.params.id
    this.fetchInstituicao();
    this.fetchCampeonato(campeonato_id);
    this.fetchCampeonatos(campeonato_id);
    this.fetchEquipes(campeonato_id)

  },

  methods: {
    fetchInstituicao() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/instituicoes/s/${this.$route.params.slug}`)
        .then((response) => {
          if (response.data) {
            this.instituicao = response.data;
          }
        })
        .catch((error) => {
          // this.$toast.error('Erro ao buscar posições.');
          console.error("Erro ao buscar posições:", error);
        });
    },
    fetchEquipes(campeonato_id) {
      axios
        .get(`${process.env.VUE_APP_API_URL}/equipes?index=0&bean.campeonato=${campeonato_id}`)
        .then((response) => {
          if (response.data.content) {
            this.equipes = response.data.content;
          }
          this.totalElements = response.data.content.totalElements;
          this.totalPages = response.data.content.totalPages;
          this.currentPage = page + 1;
        })
        .catch((error) => {
          console.error("Erro ao buscar temporadas:", error);
        });
    },
    fetchCampeonato(campeonato_id) {
      axios
        .get(`${process.env.VUE_APP_API_URL}/campeonatos/${campeonato_id}`)
        .then((response) => {
          if (response.data) {
            this.campeonato = response.data;
          }
        })
        .catch((error) => {
          console.error("Erro ao buscar temporada:", error);
        });
    },
    fetchCampeonatos(campeonato_id) {
      axios
      .get(`${process.env.VUE_APP_API_URL}/campeonatos?index=0&bean.temporada=${campeonato_id}`)
      .then((response) => {
          if (response.data) {
            this.campeonatos = response.data.content;
          }
        })
        .catch((error) => {
          console.error("Erro ao buscar campeonatos:", error);
        });
    },
   
  },
  setup() {
    // const toast = useToast();
    // return { toast };
  },
};
</script>

<style scoped>
/* Estilos específicos para a lista de posições */
</style>
<style>
.hidden_header {
  display: none;
}
</style>