import { render, staticRenderFns } from "./HomeTemporada.vue?vue&type=template&id=65a24480&scoped=true"
import script from "./HomeTemporada.vue?vue&type=script&lang=js"
export * from "./HomeTemporada.vue?vue&type=script&lang=js"
import style0 from "./HomeTemporada.vue?vue&type=style&index=0&id=65a24480&prod&scoped=true&lang=css"
import style1 from "./HomeTemporada.vue?vue&type=style&index=1&id=65a24480&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65a24480",
  null
  
)

export default component.exports