<template>
  <div>
    <b-breadcrumb>
    <b-breadcrumb-item href="/">
      <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
    </b-breadcrumb-item>
    <b-breadcrumb-item active>{{instituicao.name}}</b-breadcrumb-item>

  </b-breadcrumb>
    <div class="d-flex align-items-center">
  <b-avatar variant="info" :src="'data:image/png;base64,' + instituicao.logo" class="mr-3" size="6rem"></b-avatar>
  <b-jumbotron  :lead="instituicao.description">
    <p>For more information, visit the <a :href="instituicao.website" target="_blank" rel="noopener noreferrer">website</a>.</p>
  </b-jumbotron>
</div>
  <b-tabs content-class="mt-3">
    <b-tab title="Ultimos jogos"><p>Ultimos jogos</p></b-tab>
    <b-tab title="proximos jogos" active><p>proximos jogos</p></b-tab>
    <b-tab title="Temporadas" active>
      <h3>Temporadas</h3>
      <b-table
      id="my-table"
      :items="temporadas"
      :per-page="10"
      :current-page="currentPage"
      :fields="[{key:'actions'}]"
      striped
      responsive="sm"
      thead-class="hidden_header"
    >
      <template v-slot:cell(actions)="row">
        <a :href="`/ioa/${instituicao.slug}/t/${row.item.id}`">{{row.item.description}}</a>
      </template>
    </b-table>
    </b-tab>
    <b-tab title="Destaques" disabled><p>I'm a disabled tab!</p></b-tab>
  </b-tabs>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HomeInstituicao",
  props: ["slug"],

  data() {
    return {
      instituicao: {},
      posicoes: [],
      currentPage: 1,
      totalPages: 0,
      temporadas:[],
      fields: ["id", "name", "description", "actions"],
    };
  },

  computed: {
    rows() {
      return this.posicoes.length;
    },
  },
  mounted() {
    this.fetchInstituicao();
    this.fetchTemporadas()
  },

  methods: {
    fetchInstituicao() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/instituicoes/s/${this.$route.params.slug}`)
        .then((response) => {
          if (response.data) {
            this.instituicao = response.data;
          }
        })
        .catch((error) => {
          // this.$toast.error('Erro ao buscar posições.');
          console.error("Erro ao buscar posições:", error);
        });
    },
    fetchTemporadas(page = 0) {
      axios
        .get(`${process.env.VUE_APP_API_URL}/temporadas?index=${page}`)
        .then((response) => {
          if (response.data.content) {
            this.temporadas = response.data.content;
          }
          this.totalElements = response.data.content.totalElements;
          this.totalPages = response.data.content.totalPages;
          this.currentPage = page + 1;
        })
        .catch((error) => {
          console.error("Erro ao buscar temporadas:", error);
        });
    },
    goToNewPostForm() {
      this.$router.push("/admin/instituicoes/criar");
    },
    editPost(postId) {
      this.$router.push({ path: `/admin/instituicoes/${postId}` });
    },
  },
  setup() {
    // const toast = useToast();
    // return { toast };
  },
};
</script>

<style scoped>
/* Estilos específicos para a lista de posições */
</style>
<style>
.hidden_header {
  display: none;
}
</style>