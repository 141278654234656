// alertService.js
import Vue from "vue";

const AlertService = new Vue({
  data() {
    return {
      errorMessage: "",
    };
  },
  methods: {
    showError(message) {
      this.errorMessage = message;
    },
    clearError() {
      this.errorMessage = "";
    },
  },
});

export default AlertService;
