import Vue from 'vue';
import Router from 'vue-router';
import LoginForm from '../components/LoginForm.vue';
import HomeArea from '../components/home/HomeArea.vue';
import AdminArea from '../components/admin/AdminArea.vue';
import UserArea from '../components/user/UserArea.vue';
import AdminPosicoes from '../components/admin/AdminPosicoes.vue';
import AdminCategoria from '../components/admin/AdminCategoria.vue';
import AdminCategoriaForm from '../components/admin/AdminCategoriaForm.vue';
import AdminInstituicao from '../components/admin/AdminInstituicao.vue';
import HomeInstituicao from '../components/home/HomeInstituicao.vue';
import HomeTemporada from '../components/home/HomeTemporada.vue';
import HomeCampeonato from '../components/home/HomeCampeonato.vue';
import AdminInstituicaoForm from '../components/admin/AdminInstituicaoForm.vue';
import AdminInstituicaoView from '../components/admin/AdminInstituicaoView.vue';
import AdminTemporadaView from '../components/admin/AdminTemporadaView.vue';
import AdminCampeonatoView from '../components/admin/AdminCampeonatoView.vue';
import AdminTemporadaForm from '../components/admin/AdminTemporadaForm.vue';
import AdminTemporadaCategoriaForm from '../components/admin/AdminTemporadaCategoriaForm.vue';
import AdminEquipeForm from '../components/admin/AdminEquipeForm.vue';
import AdminJogoForm from '@/components/admin/AdminJogoForm.vue';
import AdminLocal from '@/components/admin/AdminLocal.vue';
import AdminLocalForm from '@/components/admin/AdminLocalForm.vue';
import NotFound from '@/components/NotFound.vue';
import HomeIndex from '@/components/home/HomeIndex.vue';
// Uncomment and import other components as needed
// import PositionForm from '../components/admin/PositionForm.vue';
// import CategoriaForm from '../components/admin/CategoriaForm.vue';

Vue.use(Router);

const routes = [

  {
    path: '/',
    name: 'HomeArea',
    component: HomeArea,
    meta: { requiresAuth: false},
    children: [
      {
        path: '',
        component: HomeIndex,
        name:'HomeIndex',
    
      },
      {
        path: 'ioa/:slug',
        component: HomeInstituicao,
        name:'HomeInstituicao',
        props: route => ({ slug: route.params.slug })
    
      },
      {
        path: 'ioa/:slug/t/:id',
        component: HomeTemporada,
        name:'HomeTemporada',
        props: route => ({ slug: route.params.slug, id: route.params.id })
    
      },
      {
        path: 'ioa/:slug/cp/:id',
        component: HomeCampeonato,
        name:'HomeCampeonato',
        props: route => ({ slug: route.params.slug, id: route.params.id })
    
      },
    ]
  
  },
  {
    path: '/user',
    name: 'UserArea',
    component: UserArea,
    meta: { requiresAuth: true, role: 'user' }
  },
  {
    path: '/login',
    name: 'LoginForm',
    component: LoginForm
  },
  {
    path: '/admin',
    name: 'AdminArea',
    component: AdminArea,
    meta: { requiresAuth: true, role: 'admin' },
    children: [
      {
        path: 'posicoes',
        name: 'AdminPosicoes',
        component: AdminPosicoes
      },
      {
        path: 'locais',
        name: 'AdminLocal',
        component: AdminLocal
      },
      {
        path: 'locais/criar',
        name: 'AdminLocalForm',
        component: AdminLocalForm,
        props: { editing: false }

      },
      {
        path: 'categorias',
        name: 'AdminCategoria',
        component: AdminCategoria
      },
      {
        path: 'instituicoes',
        name: 'AdminInstituicao',
        component: AdminInstituicao
      },
      {
        path: 'instituicoes/criar',
        name: 'AdminInstituicaoForm',
        component: AdminInstituicaoForm,
        props: { editing: false }

      },
      {
        path: 'categorias/criar',
        name: 'AdminCategoriaForm',
        component: AdminCategoriaForm,
        props: { editing: false }

      },
      {
        path: 'instituicoes/:id',
        component: AdminInstituicaoForm,
        props: route => ({ editing: true, id: parseInt(route.params.id) })
      },
      {
        path: 'instituicoes/v/:id',
        component: AdminInstituicaoView,
        props: route => ({ editing: true, id: parseInt(route.params.id) })
      },
      {
        path: 'temporada/v/:id',
        component: AdminTemporadaView,
        props: route => ({ id: parseInt(route.params.id) })
      },
      {
        path: 'campeonato/v/:id',
        component: AdminCampeonatoView,
        props: route => ({ id: parseInt(route.params.id) })
      },
      {
        path: 'equipe',
        component: AdminEquipeForm,
        props: route => ({ id: parseInt(route.query.campeonato) })
      },
      {
        path: 'jogo',
        component: AdminJogoForm,
        props: route => ({ id: parseInt(route.query.campeonato) })
      },
      {
        path: 'instituicoes/:inst/temporada',
        component: AdminTemporadaForm,
        props: route => ({ editing: false, inst: parseInt(route.params.inst) })
      },
      {
        path: 'temporada/:id/categoria',
        component: AdminTemporadaCategoriaForm,
        props: route => ({ id: parseInt(route.params.id) })
      },
      // Uncomment and configure additional child routes as needed
      // {
      //   path: 'posicoes/nova',
      //   component: PositionForm,
      //   props: { editing: false }
      // },
      // {
      //   path: 'posicoes/:id',
      //   component: PositionForm,
      //   props: route => ({ editing: true, positionId: parseInt(route.params.id) })
      // },
      // {
      //   path: 'categorias/add',
      //   component: CategoriaForm,
      //   props: { mode: 'add' }
      // },
      // {
      //   path: 'categorias/:id',
      //   component: CategoriaForm,
      //   props: route => ({ mode: 'edit', categoria: this.categorias.find(c => c.id === route.params.id) })
      // }
    ]
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
];

const router = new Router({
  mode: 'history',
  routes
});

function getUserRole() {
  // Esta função deve retornar o papel do usuário atualmente autenticado
  // Por exemplo, 'admin' ou 'user'. Aqui está um exemplo mock:
  return 'admin'; // ou 'user'
}

// Guardas de rota para verificar autenticação e permissão
router.beforeEach((to, from, next) => {
  const userRole = getUserRole();

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (userRole) {
      if (to.matched.some(record => record.meta.role === userRole)) {
        next();
      } else {
        next({ name: 'Home' }); // Redireciona para Home se não tiver permissão
      }
    } else {
      next({ name: 'Home' }); // Redireciona para Home se não estiver autenticado
    }
  } else {
    next(); // Permite o acesso se a rota não exigir autenticação
  }
});

export default router;
