<template>
   <b-container fluid>
      <b-row class="justify-content-center">
        <b-col cols="12" md="8" lg="6">
          <h2 class="text-center">Jogo</h2>
          <b-form @submit.prevent="createJogo">
         
            <b-form-group label="Equipe A" label-for="input-equipe-a">
              <b-form-select
                id="input-equipe-a"
                v-model="jogo.equipe_a"
                :options="equipes"
                required
                :state="isValidEquipeA"
              ></b-form-select>
            </b-form-group>
            <b-form-group label="Equipe B" label-for="input-equipe-b">
              <b-form-select
                id="input-equipe-b"
                v-model="jogo.equipe_b"
                :options="equipes"
                required
                :state="isValidEquipeB"
                ></b-form-select>
                <b-form-invalid-feedback v-if="equipeAandBEqual">
              Equipe A e Equipe B não podem ser iguais!
            </b-form-invalid-feedback>
              </b-form-group>
             
              
            <b-form-group label="Local" label-for="input-local">
              <b-form-select
                id="input-local"
                v-model="jogo.local"
                :options="locais"
                required
              ></b-form-select>
            </b-form-group>
            <b-row class="mt-4">
              <b-col cols="6">
                <b-button @click="goBack" variant="secondary" block>Voltar</b-button>
              </b-col>
              <b-col cols="6" class="text-right">
                <b-button type="submit" variant="primary" block>Salvar</b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 75,
        height: 75,
        class: 'm1',
      },
      jogo: {
        equipe_a : null,
        equipe_b : null,
        campeonato : null,
        local: null,
      },
      equipes: [],
      locais: [],
    };
  },
  mounted() {
    this.jogo.campeonato = parseInt(this.$route.query.campeonato);

      // axios
      //   .get(`${process.env.VUE_APP_API_URL}/temporadas/${id}`)
      //   .then((response) => {
      //     this.temporada = response.data;
      //     console.log('temporada  carregado:', response.data);
      //   })
      //   .catch((error) => {
      //     console.error('Erro ao carregar temporadda:', error);
      //   });
    
    this.fetchEquipes(this.jogo.campeonato);
    this.fetchLocais();
  },
  computed: {
    equipeAandBEqual() {
      return this.jogo.equipe_a !== null && this.jogo.equipe_b !== null && this.jogo.equipe_a === this.jogo.equipe_b;
    },
    isValidEquipeA() {
      return !this.equipeAandBEqual || this.jogo.equipe_b === null;
    },
    isValidEquipeB() {
      return !this.equipeAandBEqual || this.jogo.equipe_a === null;
    }
  },
  methods: {
    
    createJogo() {
      // this.jogo.temporada = this.temporada.id;
      // this.jogo.jogo = this.jogo;
      if (!this.equipeAandBEqual){ 
      axios
        .post(`${process.env.VUE_APP_API_URL}/jogos`, this.jogo)
        .then((response) => {
          console.log('Novo jogo criado:', response.data);
          this.$router.push(`/admin/campeonato/v/${this.$route.query.campeonato}` );
        })
        .catch((error) => {
          console.error('Erro ao criar novo jogo:', error);
        });
      }
    },
    fetchEquipes(id, page = 0) {
      axios
      .get(`${process.env.VUE_APP_API_URL}/equipes?index=${page}&bean.campeonato=${id}`)
      .then((response) => {
          this.equipes = response.data.content.map((op) => ({
          text : op.nome,
          value: op.id
        }));
      })
      .catch((error) => {
        // this.$toast.error('Erro ao buscar posições.');
        console.error("Erro ao buscar jogos:", error);
      });
    },
    fetchLocais(page = 0) {
      axios
      .get(`${process.env.VUE_APP_API_URL}/locais?index=${page}`)
      .then((response) => {
          this.locais = response.data.content.map((op) => ({
          text : op.nome,
          value: op.id
        }));
      })
      .catch((error) => {
        // this.$toast.error('Erro ao buscar posições.');
        console.error("Erro ao buscar jogos:", error);
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
/* Adicione estilos personalizados aqui, se necessário */
</style>
