<template>
  <div>
    <h2>Instituicao</h2>
    <b-form @submit.prevent="createPost">
      <b-form-group label="Nome">
        <b-form-input
          v-model="newPost.nome"
          @blur="generateSlug"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Abreviacao">
        <b-form-input
          v-model="newPost.slug"
          :readonly="isSlugReadOnly"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Descricao">
        <b-form-textarea
          v-model="newPost.description"
          rows="3"
          required
        ></b-form-textarea>
      </b-form-group>
      <b-form-group label="Logo">
        <b-img
          :src="'data:image/png;base64,'+newPost.logo"
          thumbnail
          rounded="circle"
          fluid
          alt="Logo da Instituicao"
          class="mb-2"
        ></b-img>
        <b-button @click="triggerUpload" variant="primary">
          <b-icon icon="cloud-upload" /> 
        </b-button>
        <b-form-file
          ref="fileInput"
          class="d-none"
          @change="uploadImage"
          accept="image/*"
        />
      </b-form-group>
      <b-button @click="goBack" class="button">Voltar</b-button>
      <b-button type="submit" variant="primary">Salvar</b-button>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios';
import slugify from 'slugify';

export default {
  data() {
    return {
      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 75,
        height: 75,
        class: 'm1',
      },
      newPost: {
        nome: '',
        description: '',
        slug: '',
        logo: '', // Adiciona um campo para a URL do logo
      },
    };
  },
  mounted() {
    const postId = this.$route.params.id;
    if (postId) {
      axios
        .get(`${process.env.VUE_APP_API_URL}/instituicoes/${postId}`)
        .then((response) => {
          this.newPost = response.data;
          this.logoSrc = response.data.logo || ''; // Atualiza a URL do logo
          console.log('Instituicao  carregado:', response.data);
        })
        .catch((error) => {
          console.error('Erro ao carregar post:', error);
        });
    }
  },
  computed: {
    isSlugReadOnly() {
      return !!this.newPost.slug;
    },
    logoSrc() {
      return this.newPost.logo || 'https://via.placeholder.com/75'; // Substitua por uma imagem padrão ou URL
    },
  },
  methods: {
    triggerUpload() {
      const fileInput = this.$refs.fileInput;
      if (fileInput) {
        this.$refs.fileInput.$refs.input.click()
      } else {
        console.error('fileInput não encontrado');
      }
    },
    async uploadImage() {
      const fileInput = this.$refs.fileInput.$refs.input;
      if (fileInput && fileInput.files.length) {
        const file = fileInput.files[0];
        const formData = new FormData();
        formData.append('file', file);

        try {
          const response = await axios.post(`${process.env.VUE_APP_API_URL}/instituicoes/${this.newPost.id}/upload`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          this.newPost.logo = response.data.imageUrl; // Atualiza a URL do logo
          this.logoSrc = this.newPost.logo; // Atualiza o src da imagem
          console.log('Upload bem-sucedido:', response.data);
        } catch (error) {
          console.error('Erro no upload:', error);
        }
      } else {
        console.error('Nenhum arquivo selecionado');
      }
    },
    createPost() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/instituicoes`, this.newPost)
        .then((response) => {
          console.log('Novo post criado:', response.data);
          this.$router.push({ name: 'AdminInstituicao' });
        })
        .catch((error) => {
          console.error('Erro ao criar novo post:', error);
        });
    },
    generateSlug() {
      if (!this.newPost.slug) {
        this.newPost.slug = slugify(this.newPost.nome, { lower: true }).slice(0, 10);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
/* Adicione estilos personalizados aqui, se necessário */
</style>
