<template>
  <div>
    <h2>Temporada</h2>
    <b-form @submit.prevent="salvar">
      <b-form-group label="Nome">
        <b-form-input
          v-model="newPost.name"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Descricao">
        <b-form-textarea
          v-model="newPost.description"
          rows="3"  
          required
        ></b-form-textarea>
      </b-form-group>
      <b-form-group label="Inicio">
      <b-form-datepicker
      id="datepicker-dateformati"
      :date-format-options="{ day: '2-digit',month: '2-digit', year: 'numeric' }"
      ></b-form-datepicker>
    </b-form-group>
      <b-form-group label="Final">
      <b-form-datepicker
      id="datepicker-dateformatf"
      :date-format-options="{ day: '2-digit',month: '2-digit', year: 'numeric' }"
      ></b-form-datepicker>
    </b-form-group>
      
      <b-button @click="goBack" class="button">Voltar</b-button>
      <b-button type="submit" variant="primary">Salvar</b-button>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 75,
        height: 75,
        class: 'm1',
      },
      newPost: {
        name: '',
        description: '',
        slug: '',
        instituicao:{
           id : this.inst
        }
      },
    };
  },
  props:{
    editing:{
      type: Boolean,
      required : true,
    }
    ,inst: {
        type: Number
    }
  },
  mounted() {
    const postId = this.$route.params.id;
    if (this.editing) {
      axios
        .get(`${process.env.VUE_APP_API_URL}/temporadas/${postId}`)
        .then((response) => {
          this.createTemporada = response.data;
          console.log('Temporada  carregada:', response.data);
        })
        .catch((error) => {
          console.error('Erro ao carregar temporada:', error);
        });
    }
  },
  computed: {
 
  },
  methods: {
  
    salvar() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/temporadas`, this.newPost)
        .then((response) => {
          console.log('Novo temporada criado:', response.data);
          this.$router.push(`instituicoes/v/${this.inst}`);
        })
        .catch((error) => {
          console.error('Erro ao criar novo temporada:', error);
        });
    },
  
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
/* Adicione estilos personalizados aqui, se necessário */
</style>
