<template>
  <b-container fluid>
    <b-row class="justify-content-center">
      <b-col cols="12" md="8" lg="6">
        <h2 class="text-center">Categoria</h2>
        <b-form @submit.prevent="createCategoria">
          <b-form-group label="Nome" label-for="input-name">
            <b-form-input
              id="input-name"
              v-model="newCategoria.nome"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Instituição" label-for="input-instituicao">
            <b-form-select
              id="input-instituicao"
              v-model="selectedInstituicao"
              :options="instituicoes"
            ></b-form-select>
          </b-form-group>

          <b-row class="mt-4">
            <b-col cols="6">
              <b-button @click="goBack" variant="secondary" block>Voltar</b-button>
            </b-col>
            <b-col cols="6" class="text-right">
              <b-button type="submit" variant="primary" block>Salvar</b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 75,
        height: 75,
        class: 'm1',
      },
      newCategoria: {
        name: '',
      },
      selectedInstituicao: null,
      instituicoes: []
    };
  },
  mounted() {
    const categoriaId = this.$route.params.id;
    if (categoriaId) {
      axios
        .get(`${process.env.VUE_APP_API_URL}/categorias/${categoriaId}`)
        .then((response) => {
          this.newCategoria = response.data;
          this.logoSrc = response.data.logo || ''; // Atualiza a URL do logo
          console.log('Categoria  carregado:', response.data);
        })
        .catch((error) => {
          console.error('Erro ao carregar categoria:', error);
        });
    }
    this.fetchInstituicoes();

  },
  computed: {
 
  },
  methods: {
    
    createCategoria() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/categorias`, this.newCategoria)
        .then((response) => {
          console.log('Novo categoria criado:', response.data);
          this.$router.push({ name: 'AdminCategoria' });
        })
        .catch((error) => {
          console.error('Erro ao criar novo categoria:', error);
        });
    },
    fetchInstituicoes() {
      // Simulação de uma requisição para obter instituições
      this.instituicoes = [
        { value: null, text: 'Selecione uma instituição' },
        { value: '1', text: 'Instituição 1' },
        { value: '2', text: 'Instituição 2' },
        { value: '3', text: 'Instituição 3' }
      ];
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
/* Adicione estilos personalizados aqui, se necessário */
</style>
