<template>
  <div>
    <h2>{{instituicao.name}}</h2>
    <b-avatar variant="info" :src="'data:image/png;base64,'+instituicao.logo"></b-avatar>

    <b-tabs>
      <b-tab title="Temporadas">
    <b-table
      id="my-table"
      :items="temporadas"
      :per-page="perPage"
      :current-page="currentPage"
      :fields="fields"
      striped
      responsive="sm"
    >
      <template v-slot:cell(actions)="row">
        <b-button @click="editTemporada(row.item.id)" variant="primary" size="sm">
          <b-icon icon="pencil"></b-icon>
        </b-button>

        <b-button @click="viewTemporada(row.item.id)" variant="primary" size="sm">
          <b-icon icon="eye"></b-icon>
        </b-button>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalElements"
      :per-page="perPage"
      @change="fetchTemporadas"
    />
    <b-button variant="success" @click="goToNovaTemporada">Nova Temporada</b-button>
      </b-tab>
      <b-tab title="Associados"></b-tab>

      </b-tabs>

    <b-button variant="primary" href="/admin/instituicoes">Voltar</b-button>
  
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      mainProps: {
        blank: true,
        blankColor: "#777",
        width: 75,
        height: 75,
        class: "m1",
      },
      perPage: 10,
      currentPage: 1,
      totalElements:0,
      temporadas:[],
      instituicao: {
        name: "",
        description: "",
        slug: "",
      },
      fields:['id', 'name', 'description', 'inicio', 'final', 'actions']
    };
  },
  mounted() {
    const postId = this.$route.params.id;
    if (postId){
      axios
        .get(`${process.env.VUE_APP_API_URL}/instituicoes/${postId}`)
        .then((response) => {
          this.instituicao = response.data;
          console.log("Nova temporada criado:", response.data);
        })
        .catch((error) => {
          console.error("Erro ao criar novo post:", error);
        });
        this.fetchTemporadas()
      }
  },
  computed: {
    
  },
  methods: {
    goToNovaTemporada() {
      this.$router.push(`/admin/instituicoes/${this.$route.params.id}/temporada`);
    },
    fetchTemporadas(page = 0) {
      axios
        .get(`${process.env.VUE_APP_API_URL}/temporadas?index=${page}`)
        .then((response) => {
          if (response.data.content) {
            this.temporadas = response.data.content;
          }
          this.totalElements = response.data.content.totalElements;
          this.totalPages = response.data.content.totalPages;
          this.currentPage = page + 1;
        })
        .catch((error) => {
          console.error("Erro ao buscar temporadas:", error);
        });
    },
    viewTemporada(_id){
      this.$router.push({ path: `/admin/temporada/v/${_id}` });
    },
  },
};
</script>

<style>
/* Adicione estilos personalizados aqui, se necessário */
</style>
4
