<template>
  <div>
    <b-navbar type="dark" variant="dark">
      <b-navbar-nav>
        <b-nav-item href="/admin">Home</b-nav-item>

        <!-- Navbar dropdowns -->
        <b-nav-item-dropdown text="Config">
          <b-dropdown-item href="/admin/instituicoes">Instituições</b-dropdown-item>
          <b-dropdown-item href="/admin/locais">Locais</b-dropdown-item>
          <b-dropdown-item href="/admin/arbitros">Árbitros</b-dropdown-item>
          <b-dropdown-item href="/admin/atletas">Atletas</b-dropdown-item>
          <b-dropdown-item href="/admin/categorias">Categorias</b-dropdown-item>
          <b-dropdown-item href="/admin/posicoes">Posições</b-dropdown-item>
          <b-dropdown-item href="/admin/status-campeonato"
            >Status de Campeonato</b-dropdown-item
          >
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown text="User" right>
          <b-dropdown-item href="login">Trocar Perfil</b-dropdown-item>
          <b-dropdown-item @click="logout">Sair</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>

    <router-view></router-view>
    <!-- <GoogleAd ad-client="ca-pub-XXXXXXXXXXXXXXXX" ad-slot="XXXXXXXXXX" /> -->
  </div>
</template>

<script>
export default {
  name: "AdminHome",
  methods: {
    logout() {
      localStorage.removeItem("isAuthenticated");
      this.$router.push("/");
    },
  },
};
</script>

<style scoped></style>
