<template>
  <div class="not-found">
    <h1>Oops! Página não encontrada.</h1>
    <p>Parece que você se perdeu...</p>
    <img src="/giphy.webp" alt="Confused Travolta" />
    <p>
      Vamos te levar de volta para a
      <router-link to="/">página inicial</router-link>.
    </p>
    <button @click="goBack" class="button">Voltar</button>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
.not-found {
  text-align: center;
  padding: 50px;
  color: #333;
}

.not-found h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.not-found p {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.not-found img {
  width: 300px;
  height: auto;
  margin-bottom: 20px;
}
</style>
