<template>
  <div class="overflow-auto">
    <h3>Instituições</h3>

    <b-table
      id="my-table"
      :items="posicoes"
      :per-page="perPage"
      :current-page="currentPage"
      :fields="fields"
      striped
      responsive="sm"
    >
      <template v-slot:cell(actions)="row">
        <b-button @click="editPost(row.item.id)" variant="primary" size="sm">
          <b-icon icon="pencil"></b-icon>
        </b-button>

        <b-button @click="viewInstituicao(row.item.id)" variant="primary" size="sm">
          <b-icon icon="eye"></b-icon>
        </b-button>
           

      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalElements"
      :per-page="perPage"
      @change="fetchPositions"
    />

    <b-button href="/admin" class="button">Voltar</b-button>

    <b-button variant="success" @click="goToNewPostForm"
      >Nova Institucao</b-button>
    
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AdminInstituicao",
  data() {
    return {
      posicoes: [],
      currentPage: 1,
      totalPages: 0,
      totalElements :0,
      fields: ["id", "nome", "description", "actions"],
      perPage:10
    };
  },

  computed: {
    rows() {
      return this.posicoes.length;
    },
  },
  mounted() {
    this.fetchPositions();
  },

  methods: {
   
    fetchPositions(page = 0) {
      axios
        .get(`${process.env.VUE_APP_API_URL}/instituicoes?index=${page}`)
        .then((response) => {
          if (response.data.content) {
            this.posicoes = response.data.content;
          }
          this.totalElements = response.data.page.totalElements;
          this.totalPages = response.data.page.totalPages;
          this.currentPage = page + 1;
        })
        .catch((error) => {
          // this.$toast.error('Erro ao buscar posições.');
          console.error("Erro ao buscar posições:", error);
        });
    },
    goToNewPostForm() {
      this.$router.push("/admin/instituicoes/criar");
    },
    editPost(postId) {
      this.$router.push({ path: `/admin/instituicoes/${postId}` });
    },
    viewInstituicao(_id) {
      this.$router.push({ path: `/admin/instituicoes/v/${_id}` });
    },
  },
  setup() {
    // const toast = useToast();
    // return { toast };
  },
};
</script>

<style scoped>
/* Estilos específicos para a lista de posições */
</style>
