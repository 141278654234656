<template>
  <div class="overflow-auto">
    <h3>Lista de Posições</h3>

    <p class="mt-3">Current Page: {{ currentPage }}</p>

    <b-table
      id="my-table"
      :items="posicoes"
      :per-page="perPage"
      :current-page="currentPage"
      :fields="fields"
      striped
      responsive="sm"
    >
      <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Age:</b></b-col>
            <b-col>{{ row.item.id }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Is Active:</b></b-col>
            <b-col>{{ row.item.isActive }}</b-col>
          </b-row>

          <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
        </b-card>
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
    ></b-pagination>
    <!-- <button @click="showAddForm">Adicionar Posição</button> -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AdminPosicoes",
  //   components: {
  //     // Pagination
  //   },
  data() {
    return {
      posicoes: [],
      currentPage: 1,
      totalPages: 0,
      fields: ["id", "description"],
    };
  },

  computed: {
    rows() {
      return this.posicoes.length;
    },
  },
  mounted() {
    this.fetchPositions();
  },

  methods: {
    fetchPositions(page = 0) {
      axios
        .get(`${process.env.VUE_APP_API_URL}/posicoes?index=${page}`)
        .then((response) => {
          this.posicoes = response.data._embedded.posicaoList;
          this.totalPages = response.data.page.totalPages;
          this.currentPage = page + 1;
        })
        .catch((error) => {
          // this.$toast.error('Erro ao buscar posições.');
          console.error("Erro ao buscar posições:", error);
        });
    },
    showAddForm() {
      this.$router.push("/positions/novo");
    },
    editPosition(position) {
      this.$router.push({
        path: `/positions/${position.id}/edit`,
        query: { position: position },
      });
    },
  },
  setup() {
    // const toast = useToast();
    // return { toast };
  },
};
</script>

<style scoped>
/* Estilos específicos para a lista de posições */
</style>
