<template>
  <div>
    <h2>{{temporada.instituicao}}</h2>
    <b-avatar variant="info" :src="'data:image/png;base64,'+temporada.instituicao_logo"></b-avatar>
    <h2>{{temporada.description}}</h2>
    <b-tabs>
      <b-tab title="Equipes">
        <b-button variant="success" @click="addNovaEquipe">Nova Equipe</b-button>
        <b-table
          id="my-table"
          :items="equipes"
          :per-page="perPage"
          :current-page="currentPage"
          :fields="fields"
          striped
          responsive="sm">
          <template v-slot:cell(actions)="row">
            <b-button @click="viewEquipe(row.item.id)" variant="primary" size="sm">
              <b-icon icon="eye"></b-icon>
            </b-button>
          </template>
      </b-table>

    </b-tab>
    <b-tab title="Jogos">
      <b-button variant="success" @click="addNovoJovo">Novo Jogo</b-button>
      <b-table
        id="my-table"
        :items="jogos"
        :per-page="10"
        :current-page="currentPage"
        :fields="[{key:'description'},{key:'local'},{key:'actions'}]"
        striped
        responsive="sm">
        <template v-slot:cell(description)="row">
          {{ row.item.equipe_a }} x {{ row.item.equipe_b }} 
        </template>
        <template v-slot:cell(actions)="row">
          <b-button @click="viewJogo(row.item.id)" variant="primary" size="sm">
            <b-icon icon="eye"></b-icon>
          </b-button>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalElements"
        :per-page="10"
        @change="fetchJogos"/>
      </b-tab>
    </b-tabs>
    <b-button variant="primary" href="`/admin/instituicoes/v/`">Voltar</b-button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      mainProps: {
        blank: true,
        blankColor: "#777",
        width: 75,
        height: 75,
        class: "m1",
      },
      perPage: 10,
      page:1,
      currentPage: 1,
      totalElements:0,
      jogos:[],
      equipes:[],
      temporada: {},
      fields:['id', 'nome', 'actions']
    };
  },
  mounted() {
    const campeonato_id = this.$route.params.id;
    if (campeonato_id){
      axios
        .get(`${process.env.VUE_APP_API_URL}/campeonatos/${campeonato_id}`)
        .then((response) => {
          this.temporada = response.data;
          console.log("GET temporada OK:", response.data);
        })
        .catch((error) => {
          console.error("Erro ao criar novo post:", error);
        });
        this.fetchEquipes(campeonato_id)
        this.fetchJogos(campeonato_id)
      }
  },
  computed: {
    
  },
  methods: {
    addNovaCategoriaTemporada() {
      this.$router.push(`/admin/temporada/${this.$route.params.id}/categoria`);
    },
    addNovaEquipe() {
      this.$router.push(`/admin/equipe?campeonato=${this.$route.params.id}`);
    },
    addNovoJovo() {
      this.$router.push(`/admin/jogo?campeonato=${this.$route.params.id}`);
    },
    fetchJogos(campeonato_id) {
      axios
        .get(`${process.env.VUE_APP_API_URL}/jogos?index=0&bean.campeonato=${campeonato_id}`)
        .then((response) => {
          if (response.data.content) {
            this.jogos = response.data.content;
          }
          this.totalElements = response.data.content.totalElements;
          this.totalPages = response.data.content.totalPages;
          this.currentPage = page + 1;
        })
        .catch((error) => {
          console.error("Erro ao buscar jogos:", error);
        });
    },
    fetchEquipes(campeonato_id) {
      axios
        .get(`${process.env.VUE_APP_API_URL}/equipes?index=0&bean.campeonato=${campeonato_id}`)
        .then((response) => {
          if (response.data.content) {
            this.equipes = response.data.content;
          }
          this.totalElements = response.data.content.totalElements;
          this.totalPages = response.data.content.totalPages;
        })
        .catch((error) => {
          console.error("Erro ao buscar temporadas:", error);
        });
    },
    viewCampeonato(_id){
      this.$router.push({ path: `/admin/campeonato/v/${_id}` });
    },
  },
};
</script>

<style>
/* Adicione estilos personalizados aqui, se necessário */
</style>

