<template>
  <div>
    <b-breadcrumb>
    <b-breadcrumb-item href="/">
      <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
    </b-breadcrumb-item>
    <b-breadcrumb-item :href="`/ioa/${instituicao.slug}`">{{instituicao.name}}</b-breadcrumb-item>
    <b-breadcrumb-item active>{{temporada.description}}</b-breadcrumb-item>

  </b-breadcrumb>
    <div class="d-flex align-items-center">
  <b-avatar variant="info" :src="'data:image/png;base64,' + instituicao.logo" class="mr-3" size="6rem"></b-avatar>
  <b-jumbotron :lead="instituicao.description">
    <p>For more information, visit the <a :href="instituicao.website" target="_blank" rel="noopener noreferrer">website</a>.</p>
  </b-jumbotron>
</div>
    <b-tabs content-class="mt-3">
    <b-tab title="Ultimos jogos"><p>Ultimos jogos</p></b-tab>
    <b-tab title="proximos jogos"><p>proximos jogos</p></b-tab>
    <b-tab title="Categorias" >
      <h3>Categorias</h3>
      <b-table
      id="my-table"
      :items="campeonatos"
      :per-page="10"
      :current-page="currentPage"
      :fields="[{key:'actions'}]"
      striped
      responsive="sm"
      thead-class="hidden_header"
    >
      <template v-slot:cell(actions)="row">
        <a :href="`/ioa/${instituicao.slug}/cp/${row.item.id}`">{{row.item.nome}}</a>
      </template>
    </b-table>
   
    </b-tab>
    <b-tab title="Destaques" disabled><p>I'm a disabled tab!</p></b-tab>
  </b-tabs>

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HomeTemporada",
  props: ["slug"],

  data() {
    return {
      instituicao: {},
      posicoes: [],
      currentPage: 1,
      totalPages: 0,
      temporada:{},
      campeonatos: [],
      fields: ["id", "name", "description", "actions"],
    };
  },

  computed: {
    rows() {
      return this.posicoes.length;
    },
  },
  mounted() {
    const temporada_id = this.$route.params.id
    this.fetchInstituicao();
    this.fetchTemporada(temporada_id);
    this.fetchCampeonatos(temporada_id);
  },

  methods: {
    fetchInstituicao() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/instituicoes/s/${this.$route.params.slug}`)
        .then((response) => {
          if (response.data) {
            this.instituicao = response.data;
          }
        })
        .catch((error) => {
          // this.$toast.error('Erro ao buscar posições.');
          console.error("Erro ao buscar posições:", error);
        });
    },
    fetchTemporada(temporada_id) {
      axios
        .get(`${process.env.VUE_APP_API_URL}/temporadas/${temporada_id}`)
        .then((response) => {
          if (response.data) {
            this.temporada = response.data;
          }
        })
        .catch((error) => {
          console.error("Erro ao buscar temporada:", error);
        });
    },
    fetchCampeonatos(temporada_id) {
      axios
      .get(`${process.env.VUE_APP_API_URL}/campeonatos?index=0&bean.temporada=${temporada_id}`)
      .then((response) => {
          if (response.data) {
            this.campeonatos = response.data.content;
          }
        })
        .catch((error) => {
          console.error("Erro ao buscar campeonatos:", error);
        });
    },
   
  },
  setup() {
    // const toast = useToast();
    // return { toast };
  },
};
</script>

<style scoped>
/* Estilos específicos para a lista de posições */
</style>
<style>
.hidden_header {
  display: none;
}
</style>