<template>
     <div class="d-flex flex-row flex-wrap">
    <b-card
      tag="article"
      img-top
      style="max-width: 10rem"
      v-for="post in posicoes"
      :key="post.id"
      :title="post.name"
      class="m-2"
      :img-src="'data:image/png;base64,'+ post.logo"
    >
      <!-- 
        <b-card  img-src="https://picsum.photos/600/300/?image=25" img-alt="Image" img-top
            tag="article" style="max-width: 10rem;" class="mb-3"> -->
      <b-card-text>
        {{ post.description }}
      </b-card-text>

      <b-button :href="`/ioa/${post.slug}`" variant="primary"
        >Go somewhere</b-button
      >
    </b-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      errorMessage: "",
      posicoes: [],
    };
  },
  name: "HomeArea",
  mounted() {
    this.fetch();
  },
  methods: {
    fetch(page = 0) {
      axios
        .get(`${process.env.VUE_APP_API_URL}/instituicoes?index=${page}`)
        .then((response) => {
          this.posicoes = response.data.content;
        })
        .catch((error) => {
          // this.$toast.error('Erro ao buscar posições.');
          console.error("Erro ao buscar posições:", error);
          // this.errorMessage = `Error: ${error.message}`;
        });
    },
    showAddForm() {
      this.$router.push("/positions/novo");
    },
    editPosition(position) {
      this.$router.push({
        path: `/positions/${position.id}/edit`,
        query: { position: position },
      });
    },
  },
};
</script>

<style scoped>
/* Adicione estilos aqui */
</style>
