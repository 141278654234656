<template>
  <div class="overflow-auto">
    <h3>Locais</h3>

    <b-table
      id="table_locais"
      :items="locais"
      :per-page="perPage"
      :current-page="currentPage"
      :fields="fields"
      striped
      responsive="sm">
      <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Age:</b></b-col>
            <b-col>{{ row.item.id }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Is Active:</b></b-col>
            <b-col>{{ row.item.isActive }}</b-col>
          </b-row>

          <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
        </b-card>
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
    ></b-pagination>
    <b-button href="/admin" class="button">Voltar</b-button>

<b-button variant="success" @click="goToNewLocal"
  >Novo Local</b-button>  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AdminCategoria",
  //   components: {
  //     // Pagination
  //   },
  data() {
    return {
      perPage:10,
      locais: [],
      currentPage: 1,
      totalPages: 0,
      fields: ["id", "nome", "instituicao"],
    };
  },

  computed: {
    rows() {
      return this.locais.length;
    },
  },
  mounted() {
    this.fetchLocais();
  },

  methods: {
    fetchLocais(page = 0) {
      axios
        .get(`${process.env.VUE_APP_API_URL}/locais?index=${page}`)
        .then((response) => {
          this.locais = response.data.content;
          this.totalPages = response.data.content.totalPages;
          this.currentPage = page + 1;
        })
        .catch((error) => {
          // this.$toast.error('Erro ao buscar posições.');
          console.error("Erro ao buscar locais:", error);
        });
    },
    goToNewLocal() {
      this.$router.push("/admin/locais/criar");
    },
    
  },
  setup() {
    // const toast = useToast();
    // return { toast };
  },
};
</script>

<style scoped>
/* Estilos específicos para a lista de posições */
</style>
