<template>
  <div>
    <!-- <b-alert v-if="errorMessage" variant="danger" show dismissible @dismissed="errorMessage = ''">
            {{ errorMessage }}
        </b-alert> -->
    <b-navbar type="light" variant="light">
      <b-navbar-nav>
        <b-nav-item href="/">Home</b-nav-item>

        <b-nav-item-dropdown text="User" right>
          <b-dropdown-item href="/login">Login</b-dropdown-item>
          <b-dropdown-item href="#">Settings</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
   
    <router-view></router-view>

  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      errorMessage: "",
      posicoes: [],
    };
  },
  name: "HomeArea",
  mounted() {
  },
  methods: {
    fetch(page = 0) {
      axios
        .get(`${process.env.VUE_APP_API_URL}/instituicoes?index=${page}`)
        .then((response) => {
          this.posicoes = response.data.content;
          console.log('ok')
        })
        .catch((error) => {
          console.error("Erro ao buscar instituicoes:", error);
        });
    },
  },
};
</script>

<style scoped>
/* Adicione estilos aqui */
</style>
