<template>
  <div id="app">
    <b-alert
      v-if="$alert.errorMessage"
      variant="danger"
      show
      dismissible
      @dismissed="$alert.clearError()"
    >
      {{ $alert.errorMessage }}
    </b-alert>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
/* Adicione estilos globais aqui */
</style>
