<template>
  <div>
    <h2>{{temporada.instituicao}}</h2>
    <b-avatar variant="info" :src="'data:image/png;base64,'+temporada.instituicao_logo"></b-avatar>
    <h2>{{temporada.description}}</h2>
    <h3>Categoriasw</h3>
    <b-table
      id="my-table"
      :items="campeonatos"
      :per-page="perPage"
      :current-page="currentPage"
      :fields="fields"
      striped
      responsive="sm">
      <template v-slot:cell(actions)="row">
        <b-button @click="viewCampeonato(row.item.id)" variant="primary" size="sm">
          <b-icon icon="eye"></b-icon>
        </b-button>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalElements"
      :per-page="perPage"
      @change="fetchCampeonatos"/>

    <b-button variant="primary" href="`/admin/instituicoes/v/`">Voltar</b-button>
    <b-button variant="success" @click="addNovaCategoriaTemporada">Nova Categoria</b-button>
  
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      mainProps: {
        blank: true,
        blankColor: "#777",
        width: 75,
        height: 75,
        class: "m1",
      },
      perPage: 10,
      currentPage: 1,
      totalElements:0,
      campeonatos:[],
      temporada: {},
      fields:['id', 'nome', 'actions']
    };
  },
  mounted() {
    const temporada_id = this.$route.params.id;
    if (temporada_id){
      axios
        .get(`${process.env.VUE_APP_API_URL}/vw/temporadas/${temporada_id}`)
        .then((response) => {
          this.temporada = response.data;
          console.log("GET temporada OK:", response.data);
        })
        .catch((error) => {
          console.error("Erro ao criar novo post:", error);
        });
        this.fetchCampeonatos(temporada_id)
      }
  },
  computed: {
    
  },
  methods: {
    addNovaCategoriaTemporada() {
      this.$router.push(`/admin/temporada/${this.$route.params.id}/categoria`);
    },
    fetchCampeonatos(temporada_id) {
      axios
        .get(`${process.env.VUE_APP_API_URL}/campeonatos?index=0&bean.temporada=${temporada_id}`)
        .then((response) => {
          if (response.data.content) {
            this.campeonatos = response.data.content;
          }
          this.totalElements = response.data.content.totalElements;
          this.totalPages = response.data.content.totalPages;
          this.currentPage = page + 1;
        })
        .catch((error) => {
          console.error("Erro ao buscar temporadas:", error);
        });
    },
    viewCampeonato(_id){
      this.$router.push({ path: `/admin/campeonato/v/${_id}` });
    },
  },
};
</script>

<style>
/* Adicione estilos personalizados aqui, se necessário */
</style>

