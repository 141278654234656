<template>
  <b-container fluid>
    <b-row class="justify-content-center">
      <b-col cols="12" md="8" lg="6">
        <h2 class="text-center">Equipe</h2>
        <b-form @submit.prevent="createEquipe">
          <b-form-group label="Nome" label-for="input-name">
            <b-form-input
              id="input-name"
              v-model="newEquipe.nome"
              required
            ></b-form-input>
          </b-form-group>

          <b-row class="mt-4">
            <b-col cols="6">
              <b-button @click="goBack" variant="secondary" block>Voltar</b-button>
            </b-col>
            <b-col cols="6" class="text-right">
              <b-button type="submit" variant="primary" block>Salvar</b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 75,
        height: 75,
        class: 'm1',
      },
      newEquipe: {
        nome: '',
      },
      selectedInstituicao: null,
      instituicoes: []
    };
  },
  mounted() {
    const equipeId = this.$route.params.id;
    if (equipeId) {
      axios
        .get(`${process.env.VUE_APP_API_URL}/equipes/${equipeId}`)
        .then((response) => {
          this.newEquipe = response.data;
          this.logoSrc = response.data.logo || ''; // Atualiza a URL do logo
          console.log('Equipe  carregado:', response.data);
        })
        .catch((error) => {
          console.error('Erro ao carregar equipe:', error);
        });
    }
  },
  computed: {
 
  },
  methods: {
    
    createEquipe() {
      this.newEquipe.campeonato = parseInt(this.$route.query.campeonato);
      axios
        .post(`${process.env.VUE_APP_API_URL}/equipes`, this.newEquipe)
        .then((response) => {
          console.log('Novo equipe criado:', response.data);
          this.$router.push(`/admin/campeonato/v/${this.$route.query.campeonato}`);
        })
        .catch((error) => {
          console.error('Erro ao criar novo equipe:', error);
        });
    },
    fetchInstituicoes() {
      // Simulação de uma requisição para obter instituições
      this.instituicoes = [
        { value: null, text: 'Selecione uma instituição' },
        { value: '1', text: 'Instituição 1' },
        { value: '2', text: 'Instituição 2' },
        { value: '3', text: 'Instituição 3' }
      ];
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
/* Adicione estilos personalizados aqui, se necessário */
</style>
