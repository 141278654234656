<template>
  <b-container fluid>
    <b-row class="justify-content-center">
      <b-col cols="12" md="8" lg="6">
        <h2 class="text-center">Local</h2>
        <b-form @submit.prevent="createLocal">
          <b-form-group label="Nome" label-for="input-name">
            <b-form-input
              id="input-name"
              v-model="newLocal.nome"
              required
            ></b-form-input>
          </b-form-group>

       

          <b-row class="mt-4">
            <b-col cols="6">
              <b-button @click="goBack" variant="secondary" block>Voltar</b-button>
            </b-col>
            <b-col cols="6" class="text-right">
              <b-button type="submit" variant="primary" block>Salvar</b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 75,
        height: 75,
        class: 'm1',
      },
      newLocal: {
        name: '',
      },
      selectedInstituicao: null,
      instituicoes: []
    };
  },
  mounted() {
    const localId = this.$route.params.id;
    if (localId) {
      axios
        .get(`${process.env.VUE_APP_API_URL}/locais/${localId}`)
        .then((response) => {
          this.newLocal = response.data;
          this.logoSrc = response.data.logo || ''; // Atualiza a URL do logo
          console.log('Local  carregado:', response.data);
        })
        .catch((error) => {
          console.error('Erro ao carregar local:', error);
        });
    }

  },
  computed: {
 
  },
  methods: {
    
    createLocal() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/locais`, this.newLocal)
        .then((response) => {
          console.log('Novo local criado:', response.data);
          this.$router.push({ name: 'AdminLocal' });
        })
        .catch((error) => {
          console.error('Erro ao criar novo local:', error);
        });
    },
    
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
/* Adicione estilos personalizados aqui, se necessário */
</style>
