<template>
    <b-container fluid>
      <b-row class="justify-content-center">
        <b-col cols="12" md="8" lg="6">
          <h2 class="text-center">Categoria</h2>
          <b-form @submit.prevent="createCategoria">
            <b-form-group label="Nome" label-for="input-name">
              <b-form-input
                id="input-name"
                v-model="temporada.description"
                required
                aria-readonly="true"
              ></b-form-input>
            </b-form-group>
  
            <b-form-group label="Instituição" label-for="input-instituicao">
              <b-form-select
                id="input-instituicao"
                v-model="categoria"
                :options="categorias"
                required
              ></b-form-select>
            </b-form-group>
  
            <b-row class="mt-4">
              <b-col cols="6">
                <b-button @click="goBack" variant="secondary" block>Voltar</b-button>
              </b-col>
              <b-col cols="6" class="text-right">
                <b-button type="submit" variant="primary" block>Salvar</b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        mainProps: {
          blank: true,
          blankColor: '#777',
          width: 75,
          height: 75,
          class: 'm1',
        },
        temporada: {},
        selectedInstituicao: null,
        categorias: [],
        categoria: {},
        campeonato:{}
      };
    },
    mounted() {
      const id = this.$route.params.id;
        axios
          .get(`${process.env.VUE_APP_API_URL}/temporadas/${id}`)
          .then((response) => {
            this.temporada = response.data;
            console.log('temporada  carregado:', response.data);
          })
          .catch((error) => {
            console.error('Erro ao carregar temporadda:', error);
          });
      
      this.fetchCategorias(id);
    },
    computed: {
   
    },
    methods: {
      
      createCategoria() {
        this.campeonato.temporada = this.temporada.id;
        this.campeonato.categoria = this.categoria;

        axios
          .post(`${process.env.VUE_APP_API_URL}/campeonatos`, this.campeonato)
          .then((response) => {
            console.log('Novo categoria criado:', response.data);
            this.$router.push(`/admin/temporada/v/${this.temporada.id}` );
          })
          .catch((error) => {
            console.error('Erro ao criar novo categoria:', error);
          });
      },
      fetchCategorias(id, page = 0) {
        axios
        .get(`${process.env.VUE_APP_API_URL}/categorias?index=${page}&bean.temporada=${id}`)
        .then((response) => {
            this.categorias = response.data.content.map((op) => ({
            text : op.nome,
            value: op.id
          }));
        })
        .catch((error) => {
          // this.$toast.error('Erro ao buscar posições.');
          console.error("Erro ao buscar categorias:", error);
        });
      },
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script>
  
  <style>
  /* Adicione estilos personalizados aqui, se necessário */
  </style>
  