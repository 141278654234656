<template>
  <div>
    <h1>Área do Usuário</h1>
    <!-- Conteúdo específico para usuários -->
    usuario
  </div>
</template>

<script>
export default {
  name: "UserArea",
};
</script>

<style scoped>
/* Adicione estilos aqui */
</style>
