import "./lib/css";
import "./lib/script";
import "./lib/global";


import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store"; 
import EventBus from "./lib/eventBus.js";
import axios from "axios";

import "./plugins/bootstrap-vue";
import AlertService from "./services/alertService";

// import BootstrapVue from 'bootstrap-vue'
// import "bootstrap/dist/css/bootstrap.min.css";

import goBackMixin from "./mixins/goBackMixin";

Vue.config.productionTip = false;
Vue.prototype.$bus = EventBus;
Vue.prototype.$http = axios;
Vue.config.productionTip = false;
Vue.prototype.$alert = AlertService;

Vue.mixin(goBackMixin);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    Vue.prototype.$alert.showError(`Error: ${error.message}`);

    return Promise.reject(error);
  }
);


/* eslint-disable no-new */
// new Vue({
//   el: "#app",
//   router,
//   template: "<App/>",
//   components: {
//     App,
//   },
// });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

